<template>
  <div class="w-full h-full flex space-x-1 group">
    <div class="flex-1 flex items-center space-x-2 truncate">
      <input type="checkbox" name="checkbox" @input="onCheckboxChanged"/>
      <span class="truncate">
        {{ params.displayName }}
      </span>
    </div>
    <div class="flex-none flex items-center"></div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxHeader',
  methods: {
    onCheckboxChanged (event) {
      this.params.onCheckChanged(this.params, event.target.checked)
    }
  }
}
</script>
