import httpClient from '@/utilities/http/http-client'
import { generateQueryParams } from '@/utilities/common/global-methods'

const endpoint = 'api/v2/itemPengadaan'

export default {
  getMany (params) {
    const queryParams = generateQueryParams(params)
    const url = `${endpoint}${queryParams}`
    return httpClient.get(url)
  },

  getManyProyekItemPengadaan (params) {
    const queryParams = generateQueryParams(params)
    const url = `${endpoint}/proyek${queryParams}`
    return httpClient.get(url)
  },

  updateProyekForSingleItemPengadaan (params) {
    const url = `${endpoint}/proyek/single`
    return httpClient.post(url, params)
  },

  updateProyekForAllItemPengadaan (params) {
    const url = `${endpoint}/proyek/all`
    return httpClient.post(url, params)
  }
}
