<template>
  <div class="w-full h-screen-80 flex space-x-3">
    <!--left-->
    <div class="flex-1 flex flex-col">
      <div class="flex-none flex justify-start items-center">
        <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 w-84" placeholder="Search" v-model="grid.search" @keydown.enter="grid.api.refreshServerSide({ purge: true })"/>
      </div>
      <div class="flex-1">
        <AgGridVue
          class="ag-theme-alpine w-full h-full"
          :gridOptions="grid.options"
          :modules="grid.modules"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import { generateSortString } from '@/utilities/common/global-methods'
import ItemPengadaanRepositoryV2 from '@/repositories/master/item-pengadaan-repository-v2'
import { handleEmptyState } from '@/utilities/common/grid.util'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import ProyekRepository from '@/repositories/master/proyek-repository'
import { findIndex } from 'lodash'
import CheckboxHeader from '@/views/components/ag-grid/CheckboxHeader.vue'

export default {
  name: 'ItemPengadaanSetPerProyek',
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    CheckboxHeaderComponent: CheckboxHeader
  },
  data () {
    return {
      grid: {
        api: null,
        search: null,
        modules: [ServerSideRowModelModule],
        options: {
          rowModelType: 'serverSide',
          enableCellTextSelection: true,
          pagination: true,
          paginationPageSize: 50,
          cacheBlockSize: 50,
          maxBlocksInCache: 5,
          onGridReady: (params) => {
            this.grid.api = params.api
            this.initColumns()
          },
          serverSideDatasource: {
            getRows: async (params) => {
              const { startRow, endRow } = params.request
              const res = (await ItemPengadaanRepositoryV2.getMany({
                take: endRow - startRow,
                skip: startRow,
                search: this.grid.search,
                sort: generateSortString(params.request.sortModel),
                with_details: true
              }))
              const rows = res.data.data
              params.success({ rowData: rows })
              handleEmptyState({ rowData: rows, api: params.api })
            }
          },
          getRowId: (params) => params.data.id,
          onCellClicked: (params) => this.handleCellClicked(params),
          defaultColDef: { resizable: true },
          columnDefs: [
            {
              headerName: 'Item Pengadaan',
              field: 'nama',
              width: 280,
              pinned: 'left',
              sortable: true,
              unSortIcon: true,
              sort: 'asc'
            }
          ]
        }
      }
    }
  },
  methods: {
    async initColumns () {
      const listProyek = await this.getAllProyek()
      const proyekColumn = {
        headerName: 'Proyek',
        flex: 1,
        children: listProyek.map(item => {
          return {
            colId: item.id,
            headerName: item.kode,
            headerTooltip: item.nama,
            headerComponent: 'CheckboxHeaderComponent',
            headerComponentParams: (params) => {
              return { onCheckChanged: this.handleHeaderClicked }
            },
            width: 100,
            cellRenderer: this.checkboxCellRenderer
          }
        })
      }

      this.grid.api.setColumnDefs([
        ...this.grid.api.getColumnDefs(),
        proyekColumn
      ])
    },

    checkboxCellRenderer (params) {
      const idProyek = params.colDef.colId.toString()
      const idItemPengadaan = params.node.id.toString()
      const index = findIndex(params.node.data.details, it => {
        return it.id_proyek.toString() === idProyek && it.id_item_pengadaan.toString() === idItemPengadaan
      })
      const isChecked = index !== -1

      // create checkbox
      const checkboxEl = document.createElement('input')
      checkboxEl.type = 'checkbox'
      checkboxEl.name = 'checkbox'
      checkboxEl.checked = isChecked

      return checkboxEl
    },

    handleCellClicked (params) {
      const idProyek = params.colDef.colId.toString()
      const idItemPengadaan = params.node.id.toString()
      const row = params.node.data
      const details = row.details
      const index = findIndex(details, item => {
        return item.id_proyek.toString() === idProyek && item.id_item_pengadaan.toString() === idItemPengadaan
      })

      const isAdded = index === -1
      if (isAdded) {
        details.push({ id_proyek: idProyek, id_item_pengadaan: idItemPengadaan })
      } else {
        details.splice(index, 1)
      }

      // save
      this.updateProyekForSingleItemPengadaan(isAdded, idProyek, idItemPengadaan)

      // update grid data
      const newData = { ...row, details: details }
      params.node.updateData(newData)
    },

    handleHeaderClicked (params, isAdded) {
      const idProyek = params.column.colId.toString()

      // save
      this.updateProyekForAllItemPengadaan(isAdded, idProyek)

      // update grid data
      params.api.forEachNode((node) => {
        const details = node.data.details
        const index = findIndex(details, it => it.id_proyek.toString() === idProyek)
        const isProyekExist = index !== -1
        if (isAdded) {
          if (!isProyekExist) {
            details.push({ id_item_pengadaan: node.data.id, id_proyek: idProyek })
          }
        } else {
          if (isProyekExist) {
            details.splice(index, 1)
          }
        }
        const newData = { ...node.data, details: details }
        node.updateData(newData)
      })
    },

    async getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }

      const res = await ProyekRepository.getAll(params)
      return res.data.data
    },

    async updateProyekForSingleItemPengadaan (isAdded, idProyek, idItemPengadaan) {
      const params = {
        id_proyek: idProyek,
        id_item_pengadaan: idItemPengadaan,
        type: isAdded ? 'added' : 'removed'
      }
      return await ItemPengadaanRepositoryV2.updateProyekForSingleItemPengadaan(params)
        .catch((error) => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    async updateProyekForAllItemPengadaan (isAdded, idProyek) {
      const params = {
        id_proyek: idProyek,
        type: isAdded ? 'added' : 'removed'
      }
      return await ItemPengadaanRepositoryV2.updateProyekForAllItemPengadaan(params)
        .catch((error) => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    }
  }
}
</script>
